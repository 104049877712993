@import "../../variables.scss";

.upload-file-modal-container {
  padding: 10px;
  h1 {
    font-size: 1.2rem;
    font-weight: 500;
  }
  .type-field {
    margin-top: 25px;
    select {
      height: 28px;
      margin-left: 5px;
    }
  }
  .file-field {
    margin-top: 12px;
  }
}

.upload-file-dialog {
  max-width: 400px;
  left: calc((100vw - 400px) / 2);
  .modal-body, .modal-footer {
    background-color: #fff;
  }
  .action-button {
    padding-left: 30px;
    padding-right: 30px;
    background-color: #fa5252;
    color: #fff;
    border: 1px solid #fa5252;
    border-radius: 0;
  }
  .action-button-cancel {
    background-color: #ddd;
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 15px;
    border-radius: 0;
    border: 1px solid #bdbdbd;
  }
}