@import "../../variables.scss";

.admin-layout-container {
  @import "Header/Header.scss";
  @import "Sidebar/Sidebar.scss";
  display: flex;
  font-family: 'Roboto', sans-serif;
  .content-area {
    width: 100%;
    background-color: #f5f5f5;
    .layout-content {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      min-height: calc(100vh - 50px);
      .content {
        flex-basis: 100%;
        padding: 10px 15px;
        margin-left: 0;
        padding-top: 30px;

        @media (min-width: 768px) {
          flex-basis: calc(100% - 140px);
          padding: 10px 15px;
          margin-left: 140px;
        }
        @media (min-width: 992px) {
          padding: 10px 15px;
        }
      }
    }
  }
}

@import "TermOfUseModal/TermOfUseModal.scss";
