@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);
.pagination-container {
  display: flex;
  margin-top: 5px;
  align-items: center; }
  .pagination-container .pagination-page,
  .pagination-container .pagination-prev-page,
  .pagination-container .pagination-next-page {
    min-width: 32px;
    padding: 5px 12px;
    background-color: #f5f5f5;
    text-align: center;
    margin-right: 5px;
    border: 1px solid #dee2e6;
    font-size: 0.8rem;
    cursor: pointer; }
    .pagination-container .pagination-page.active, .pagination-container .pagination-page:hover,
    .pagination-container .pagination-prev-page.active,
    .pagination-container .pagination-prev-page:hover,
    .pagination-container .pagination-next-page.active,
    .pagination-container .pagination-next-page:hover {
      background-color: #e0e0e0; }
  .pagination-container .pagination-summary {
    margin-left: 5px;
    font-size: 0.8rem; }
  .pagination-container .pagination-dots {
    background-color: #f5f5f5; }
    .pagination-container .pagination-dots:hover {
      background-color: #f5f5f5; }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

body {
  background-color: #f5f5f5;
  color: #333;
  font-size: 0.9rem;
  font-family: "Inter", sans-serif;
  overflow-x: hidden; }
  body.no-scroll {
    overflow: hidden; }

.required {
  color: #dd4b39;
  font-size: 14px; }

a {
  color: #2196f3; }

.small-dialog {
  max-width: 500px;
  left: calc((100vw - 500px) / 2); }

.hidden {
  display: none !important; }

.success-notification,
.info-notification,
.danger-notification {
  width: 320px;
  left: calc((100% - 320px) / 2);
  opacity: 0.95;
  position: fixed;
  top: 35px;
  z-index: 1600;
  color: #ffffff;
  border-radius: 3px;
  padding: 10px 10px;
  font-size: 15px;
  box-shadow: 0 0 20px #999; }
  .success-notification .icon,
  .info-notification .icon,
  .danger-notification .icon {
    float: left; }
    .success-notification .icon i,
    .info-notification .icon i,
    .danger-notification .icon i {
      font-size: 24px; }
  .success-notification .text,
  .info-notification .text,
  .danger-notification .text {
    float: left;
    margin-left: 10px;
    margin-top: 2px;
    width: 245px; }

.success-notification {
  background-color: #4caf50; }

.info-notification {
  background-color: #2196f3; }

.danger-notification {
  background-color: #f44336; }

button {
  font-size: 0.85rem;
  background-color: #475569;
  color: #fff;
  border: 1px solid #475569;
  padding: 6px 35px;
  font-weight: 400;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out; }
  button:hover {
    background-color: #fa5252;
    border: 1px solid #fa5252;
    color: #fff; }

.admin-button {
  font-size: 0.85rem;
  background-color: #fa5252;
  color: #fff;
  border: 1px solid #fa5252;
  padding: 6px 35px;
  font-weight: 400;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out; }
  .admin-button:hover {
    background-color: #fb8484;
    border: 1px solid #fb8484;
    color: #fff; }

.admin-users-container {
  position: relative; }
  .admin-users-container .table-container {
    margin-top: 15px; }
    .admin-users-container .table-container table {
      width: 100%;
      margin-bottom: 10px; }
      .admin-users-container .table-container table tr {
        border-bottom: 1px solid #adb5bd; }
        .admin-users-container .table-container table tr:nth-child(odd) {
          background-color: #e5e7eb; }
        .admin-users-container .table-container table tr.header-row {
          background-color: transparent; }
      .admin-users-container .table-container table th,
      .admin-users-container .table-container table td {
        padding: 8px 10px;
        font-size: 0.95rem;
        vertical-align: top; }
      .admin-users-container .table-container table th {
        font-weight: 600; }
        .admin-users-container .table-container table th .sort-item {
          cursor: pointer; }
      .admin-users-container .table-container table .id-column {
        width: 80px;
        display: none; }
      .admin-users-container .table-container table .name-column .name {
        display: none; }
      .admin-users-container .table-container table .name-column .description {
        display: block; }
        .admin-users-container .table-container table .name-column .description .description-item {
          display: flex; }
          .admin-users-container .table-container table .name-column .description .description-item .label {
            font-weight: 500;
            flex-basis: 80px; }
        .admin-users-container .table-container table .name-column .description .description-actions {
          display: flex;
          margin-top: 10px;
          grid-gap: 20px;
          gap: 20px; }
      .admin-users-container .table-container table .desktop-column {
        display: none; }
      .admin-users-container .table-container table .parent-column {
        display: none; }
      .admin-users-container .table-container table .actions-column {
        width: 50px;
        display: none; }
        .admin-users-container .table-container table .actions-column .actions-container {
          display: flex;
          justify-content: space-between;
          align-items: center; }
      .admin-users-container .table-container table .action-icon:last-child {
        margin-bottom: 0; }
      .admin-users-container .table-container table .action-icon i {
        font-size: 1.2rem;
        cursor: pointer;
        color: #333;
        -webkit-transition: all 200ms;
        transition: all 200ms; }
        .admin-users-container .table-container table .action-icon i.fa-trash {
          color: #c92a2a; }
        .admin-users-container .table-container table .action-icon i:hover {
          -webkit-transform: scale(1.2);
                  transform: scale(1.2); }
      @media (min-width: 768px) {
        .admin-users-container .table-container table th,
        .admin-users-container .table-container table td {
          padding: 8px 10px;
          font-size: 0.95rem;
          vertical-align: middle; }
        .admin-users-container .table-container table .id-column {
          display: table-cell; }
        .admin-users-container .table-container table .name-column .name {
          display: block; }
        .admin-users-container .table-container table .name-column .description {
          display: none; }
        .admin-users-container .table-container table .parent-column {
          display: table-cell; }
        .admin-users-container .table-container table .actions-column {
          width: 80px;
          display: table-cell; }
        .admin-users-container .table-container table .desktop-column {
          display: table-cell; } }
      @media (min-width: 992px) {
        .admin-users-container .table-container table th,
        .admin-users-container .table-container table td {
          padding: 10px 15px;
          font-size: 0.95rem;
          vertical-align: middle; }
        .admin-users-container .table-container table .image-column {
          width: 150px; }
        .admin-users-container .table-container table .actions-column {
          width: 90px; } }
  .admin-users-container .form-container {
    font-size: 0.9rem; }
    .admin-users-container .form-container .loading-container {
      font-size: 1rem;
      margin-top: 25px; }
    .admin-users-container .form-container h2 {
      font-size: 1.3rem;
      font-weight: 500;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      border-top: 1px solid #475569;
      padding-top: 10px; }
    .admin-users-container .form-container .fields {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 5px; }
      .admin-users-container .form-container .fields .field {
        margin-bottom: 12px;
        flex-basis: 100%; }
        .admin-users-container .form-container .fields .field .label {
          font-weight: 500; }
        .admin-users-container .form-container .fields .field select,
        .admin-users-container .form-container .fields .field input[type="text"],
        .admin-users-container .form-container .fields .field input[type="password"] {
          width: 100%;
          height: 34px;
          padding-left: 5px;
          padding-right: 5px; }
    .admin-users-container .form-container .buttons-container {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      top: 5px;
      right: 0; }
      .admin-users-container .form-container .buttons-container .save-button {
        width: 120px;
        padding-left: 0;
        padding-right: 0; }
      .admin-users-container .form-container .buttons-container .cancel-button {
        width: 120px;
        padding-left: 0;
        padding-right: 0;
        margin-right: 15px;
        background-color: #f5f5f5;
        color: #475569;
        border: 1px solid #475569; }
        .admin-users-container .form-container .buttons-container .cancel-button:hover {
          background-color: #fa5252;
          border: 1px solid #fa5252;
          color: #fff; }
    @media (min-width: 576px) {
      .admin-users-container .form-container .buttons-container {
        position: absolute; } }
    @media (min-width: 768px) {
      .admin-users-container .form-container .fields .field {
        flex-basis: 48%; }
      .admin-users-container .form-container .buttons-container .save-button {
        width: 110px; }
      .admin-users-container .form-container .buttons-container .cancel-button {
        width: 110px;
        margin-right: 20px; } }
    @media (min-width: 992px) {
      .admin-users-container .form-container .buttons-container .save-button {
        width: 140px; }
      .admin-users-container .form-container .buttons-container .cancel-button {
        width: 140px;
        margin-right: 25px; } }
  .admin-users-container h1 {
    font-size: 1.6rem;
    padding-top: 5px;
    margin-bottom: 15px;
    color: #475569; }
  .admin-users-container .header-actions-container {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .admin-users-container .header-actions-container .search-field {
      display: flex;
      align-items: center;
      flex-basis: calc(100% - 100px); }
      .admin-users-container .header-actions-container .search-field input {
        height: 32px;
        width: calc(100% - 100px);
        max-width: calc(100% - 100px);
        padding-left: 5px;
        padding-right: 5px; }
      .admin-users-container .header-actions-container .search-field button {
        width: 100px;
        margin-left: 5px;
        margin-right: 5px;
        height: 32px;
        padding: 0; }
    .admin-users-container .header-actions-container .new-button-container {
      text-align: right; }
      .admin-users-container .header-actions-container .new-button-container button {
        width: 100px;
        height: 32px;
        padding: 0; }
    @media (min-width: 576px) {
      .admin-users-container .header-actions-container h1 {
        font-size: 1.5rem; }
      .admin-users-container .header-actions-container .search-field {
        flex-basis: auto; }
        .admin-users-container .header-actions-container .search-field input {
          width: 220px; } }
    @media (min-width: 768px) {
      .admin-users-container .header-actions-container .search-field input {
        width: 220px; }
      .admin-users-container .header-actions-container .search-field button {
        width: 120px; }
      .admin-users-container .header-actions-container .new-button-container button {
        width: 120px; } }
    @media (min-width: 992px) {
      .admin-users-container .header-actions-container .search-field input {
        width: 250px; }
      .admin-users-container .header-actions-container .search-field button {
        width: 120px; }
      .admin-users-container .header-actions-container .new-button-container button {
        width: 140px; } }

.admin-categories-delete-modal-container {
  padding: 10px; }
  .admin-categories-delete-modal-container h1 {
    font-size: 1.2rem;
    font-weight: 500; }
  .admin-categories-delete-modal-container .notice {
    margin-top: 5px;
    border: 2px solid #d32f2f;
    border-left: 8px solid #d32f2f;
    padding: 8px;
    border-radius: 3px; }
    .admin-categories-delete-modal-container .notice span {
      color: #d32f2f; }
  .admin-categories-delete-modal-container p {
    margin-bottom: 10px;
    margin-top: 10px; }

.admin-categories-delete-dialog {
  max-width: 350px;
  left: calc((100vw - 350px) / 2); }
  .admin-categories-delete-dialog .modal-body, .admin-categories-delete-dialog .modal-footer {
    background-color: #fff; }
  .admin-categories-delete-dialog .action-button {
    padding-left: 30px;
    padding-right: 30px;
    background-color: #d32f2f;
    color: #fff;
    border: 1px solid #c62828;
    border-radius: 0; }
  .admin-categories-delete-dialog .action-button-cancel {
    background-color: #ddd;
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 15px;
    border-radius: 0;
    border: 1px solid #bdbdbd; }

.admin-layout-container {
  display: flex;
  font-family: 'Roboto', sans-serif; }
  .admin-layout-container .header-container {
    position: fixed;
    width: 100%;
    z-index: 100; }
    .admin-layout-container .header-container .menu {
      display: none;
      background-color: #3d495a;
      padding-right: 20px;
      color: #fff; }
      .admin-layout-container .header-container .menu .content {
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative; }
        .admin-layout-container .header-container .menu .content .logo {
          width: 140px;
          height: 100%;
          background-color: #e2e8f0;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center; }
          .admin-layout-container .header-container .menu .content .logo img {
            width: 80%; }
        .admin-layout-container .header-container .menu .content .menu-items {
          display: flex;
          align-items: center;
          color: #fff;
          font-weight: 500; }
          .admin-layout-container .header-container .menu .content .menu-items .data-type {
            margin-right: 40px; }
            .admin-layout-container .header-container .menu .content .menu-items .data-type select {
              height: 30px;
              margin-left: 5px;
              width: 130px; }
          .admin-layout-container .header-container .menu .content .menu-items .welcome {
            margin-right: 40px;
            font-weight: 400; }
          .admin-layout-container .header-container .menu .content .menu-items .menu-item {
            cursor: pointer; }
            .admin-layout-container .header-container .menu .content .menu-items .menu-item:hover {
              color: #fff; }
          .admin-layout-container .header-container .menu .content .menu-items a {
            color: #fff; }
            .admin-layout-container .header-container .menu .content .menu-items a:hover {
              color: #fff;
              text-decoration: none; }
          .admin-layout-container .header-container .menu .content .menu-items .divider {
            width: 30px;
            text-align: center;
            font-size: 0.8rem; }
        .admin-layout-container .header-container .menu .content .logout-link {
          cursor: pointer;
          margin-right: 10px;
          font-size: 0.95rem; }
          .admin-layout-container .header-container .menu .content .logout-link:hover {
            color: #fa5252; }
      @media (min-width: 768px) {
        .admin-layout-container .header-container .menu {
          display: block; } }
    .admin-layout-container .header-container .mobile-menu {
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      border-bottom: 1px solid #e0e0e0;
      padding-left: 15px;
      padding-right: 15px; }
      .admin-layout-container .header-container .mobile-menu .logo {
        width: 140px; }
        .admin-layout-container .header-container .mobile-menu .logo img {
          width: 100%; }
      .admin-layout-container .header-container .mobile-menu .menu-icon,
      .admin-layout-container .header-container .mobile-menu .cart-icon {
        font-size: 1.4rem; }
        .admin-layout-container .header-container .mobile-menu .menu-icon a,
        .admin-layout-container .header-container .mobile-menu .cart-icon a {
          color: #333; }
      @media (min-width: 576px) {
        .admin-layout-container .header-container .mobile-menu .logo {
          width: 140px; } }
      @media (min-width: 768px) {
        .admin-layout-container .header-container .mobile-menu {
          display: none; } }
    .admin-layout-container .header-container .sidebar-menu {
      position: fixed;
      background-color: #fff;
      width: 0;
      height: 100vh;
      z-index: 100;
      -webkit-transition: width 0.4s;
      transition: width 0.4s;
      overflow: hidden;
      white-space: nowrap; }
      .admin-layout-container .header-container .sidebar-menu.show-menu {
        width: 250px; }
        .admin-layout-container .header-container .sidebar-menu.show-menu .sidebar-content {
          visibility: visible; }
      .admin-layout-container .header-container .sidebar-menu .sidebar-content {
        visibility: hidden;
        overflow: hidden; }
        .admin-layout-container .header-container .sidebar-menu .sidebar-content .close-icon {
          text-align: right;
          margin-right: 18px;
          margin-top: 8px;
          font-size: 1.6rem; }
        .admin-layout-container .header-container .sidebar-menu .sidebar-content .menu-items {
          margin-top: 20px; }
          .admin-layout-container .header-container .sidebar-menu .sidebar-content .menu-items ul {
            list-style: none;
            padding-left: 25px; }
            .admin-layout-container .header-container .sidebar-menu .sidebar-content .menu-items ul li {
              margin-bottom: 16px;
              color: #862633; }
              .admin-layout-container .header-container .sidebar-menu .sidebar-content .menu-items ul li a {
                color: #862633; }
    .admin-layout-container .header-container .overlay {
      position: fixed;
      width: 0;
      height: 0;
      background-color: #333;
      opacity: 0;
      z-index: 99;
      -webkit-transition: opacity 0.4s;
      transition: opacity 0.4s; }
      .admin-layout-container .header-container .overlay.show-menu {
        opacity: 0.7;
        width: 100vh;
        width: -webkit-fill-available;
        height: 100vh; }
  .admin-layout-container .sidebar-container {
    flex-basis: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #3d495a;
    color: #f1f3f5;
    display: none;
    position: fixed;
    min-height: calc(100vh - 50px); }
    .admin-layout-container .sidebar-container .sidebar-item {
      font-size: 0.85rem;
      margin-bottom: 15px;
      cursor: pointer;
      width: 140px; }
      .admin-layout-container .sidebar-container .sidebar-item a {
        color: #f1f3f5;
        display: block;
        padding: 8px 18px; }
        .admin-layout-container .sidebar-container .sidebar-item a:hover {
          text-decoration: none;
          background-color: #fa5252;
          color: #f1f3f5 !important; }
      .admin-layout-container .sidebar-container .sidebar-item i {
        margin-right: 5px; }
      .admin-layout-container .sidebar-container .sidebar-item.active {
        background-color: #fa5252; }
    .admin-layout-container .sidebar-container .help-desk a {
      display: flex; }
      .admin-layout-container .sidebar-container .help-desk a .open-tickets {
        background-color: #d6336c;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        font-size: 0.7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 8px; }
    .admin-layout-container .sidebar-container .term-of-use {
      position: absolute;
      bottom: 15px;
      text-align: center;
      width: 140px; }
      .admin-layout-container .sidebar-container .term-of-use a {
        color: #f1f3f5; }
    @media (min-width: 768px) {
      .admin-layout-container .sidebar-container {
        display: block;
        flex-basis: 140px; } }
  .admin-layout-container .content-area {
    width: 100%;
    background-color: #f5f5f5; }
    .admin-layout-container .content-area .layout-content {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      min-height: calc(100vh - 50px); }
      .admin-layout-container .content-area .layout-content .content {
        flex-basis: 100%;
        padding: 10px 15px;
        margin-left: 0;
        padding-top: 30px; }
        @media (min-width: 768px) {
          .admin-layout-container .content-area .layout-content .content {
            flex-basis: calc(100% - 140px);
            padding: 10px 15px;
            margin-left: 140px; } }
        @media (min-width: 992px) {
          .admin-layout-container .content-area .layout-content .content {
            padding: 10px 15px; } }

.term-of-use-modal-container {
  padding: 10px;
  color: #000; }
  .term-of-use-modal-container h1 {
    padding-top: 10px;
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 15px;
    color: #000;
    margin-bottom: 30px; }
  .term-of-use-modal-container p {
    text-align: justify; }
  .term-of-use-modal-container .date {
    text-align: right; }

.term-of-use-dialog .modal-dialog {
  max-width: 900px;
  left: 0;
  background-color: #f5f5f5; }

.term-of-use-dialog .modal-body,
.term-of-use-dialog .modal-footer {
  background-color: #f5f5f5; }

.term-of-use-dialog .action-button {
  background-color: #4e738a;
  padding-left: 30px;
  padding-right: 30px;
  margin-right: 15px;
  border-radius: 0;
  color: #fff;
  border: 1px solid #4e738a;
  width: 120px; }
  .term-of-use-dialog .action-button:hover {
    background-color: #76777a;
    color: #fff; }

.term-of-use-dialog .action-button-cancel {
  background-color: #76777a;
  padding-left: 30px;
  padding-right: 30px;
  margin-right: 15px;
  border-radius: 0;
  border: 1px solid #76777a;
  color: #fff; }
  .term-of-use-dialog .action-button-cancel:hover {
    background-color: #b1b1b1;
    color: #fff; }

.dashboard-container {
  position: relative;
  padding-bottom: 30px; }
  .dashboard-container .charts-container {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 12px;
    -webkit-column-gap: 12px;
       -moz-column-gap: 12px;
            column-gap: 12px;
    justify-content: space-between; }
    .dashboard-container .charts-container h1 {
      flex-basis: 100%;
      margin-top: 10px;
      margin-bottom: 0;
      font-size: 1.5rem;
      color: #fa5252; }
    .dashboard-container .charts-container .chart-container {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
      padding: 15px;
      flex-basis: 100%; }
      .dashboard-container .charts-container .chart-container h2 {
        color: #475569;
        font-size: 1.3rem;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between; }
        .dashboard-container .charts-container .chart-container h2 .chart-title {
          width: calc(100% - 300px); }
        .dashboard-container .charts-container .chart-container h2 .chart-type-field {
          display: flex;
          align-items: center;
          font-size: 0.95rem; }
          .dashboard-container .charts-container .chart-container h2 .chart-type-field span {
            font-weight: 500; }
          .dashboard-container .charts-container .chart-container h2 .chart-type-field select {
            margin-left: 5px;
            width: 70px;
            height: 28px;
            padding-left: 5px;
            padding-right: 5px; }
      .dashboard-container .charts-container .chart-container .no-data {
        text-align: center;
        padding-top: 30px;
        font-size: 1rem; }
      .dashboard-container .charts-container .chart-container .total {
        margin-top: 15px; }
        .dashboard-container .charts-container .chart-container .total h2 {
          font-size: 1.1rem;
          display: block; }
        .dashboard-container .charts-container .chart-container .total .value {
          margin-left: 5px;
          color: #0d9488;
          font-size: 1.4rem; }
    @media (min-width: 576px) {
      .dashboard-container .charts-container .chart-container {
        flex-basis: 100%; } }
    @media (min-width: 768px) {
      .dashboard-container .charts-container .chart-container {
        flex-basis: 100%; } }
    @media (min-width: 992px) {
      .dashboard-container .charts-container .chart-container {
        flex-basis: 100%; } }
    @media (min-width: 1200px) {
      .dashboard-container .charts-container .chart-container {
        flex-basis: calc(50% - 12px); } }
  .dashboard-container h1 {
    font-size: 1.6rem;
    padding-top: 5px;
    margin-bottom: 15px;
    color: #475569; }
  .dashboard-container .header-actions-container {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .dashboard-container .header-actions-container .search-field {
      display: flex;
      align-items: center;
      flex-basis: calc(100% - 100px); }
      .dashboard-container .header-actions-container .search-field select {
        height: 32px;
        width: 120px;
        padding-left: 5px;
        padding-right: 5px;
        margin-right: 8px; }
      .dashboard-container .header-actions-container .search-field button {
        width: 100px;
        margin-right: 5px;
        height: 32px;
        padding: 0; }
  .dashboard-container .loading-data {
    margin-top: 35px;
    text-align: center;
    width: 100%;
    font-size: 0.95rem; }

.login-container {
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f5; }
  .login-container .login-container {
    margin: auto;
    padding-top: 0;
    width: 340px; }
    .login-container .login-container .logo-container {
      width: 350px;
      margin: auto;
      margin-top: calc((100vh - 600px) / 2);
      text-align: center;
      margin-bottom: 25px; }
      .login-container .login-container .logo-container img {
        width: 250px; }
    .login-container .login-container .sign-container {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border: 1px solid #e0e0e0; }
      .login-container .login-container .sign-container .sign-in-container {
        flex-basis: 100%;
        background-color: #e5e7eb;
        padding: 10px 20px;
        padding-top: 25px; }
        .login-container .login-container .sign-container .sign-in-container h1 {
          font-size: 1.5rem;
          margin-bottom: 25px;
          text-align: center; }
        .login-container .login-container .sign-container .sign-in-container .fields {
          color: #333;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          width: 100%;
          margin: auto;
          margin-top: 5px; }
          .login-container .login-container .sign-container .sign-in-container .fields .email-message {
            flex-basis: 100%;
            font-style: italic;
            text-align: right;
            margin-top: -20px;
            margin-bottom: 15px; }
          .login-container .login-container .sign-container .sign-in-container .fields .field {
            flex-basis: 100%;
            display: flex;
            margin-bottom: 20px; }
            .login-container .login-container .sign-container .sign-in-container .fields .field .icon {
              background-color: #475569;
              color: #fff;
              width: 45px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center; }
            .login-container .login-container .sign-container .sign-in-container .fields .field input {
              height: 40px;
              padding: 8px 10px;
              width: 100%;
              outline: none;
              border: 1px solid #bdbdbd; }
            .login-container .login-container .sign-container .sign-in-container .fields .field:last-child {
              margin-bottom: 0; }
        .login-container .login-container .sign-container .sign-in-container button {
          background-color: #475569;
          color: #fff;
          margin: auto;
          border: 1px solid #475569;
          padding: 6px 65px;
          margin-top: 20px;
          margin-bottom: 10px;
          font-family: "Roboto", sans-serif; }
          .login-container .login-container .sign-container .sign-in-container button:hover {
            background-color: #475569;
            border: 1px solid #475569;
            color: #fff; }
        .login-container .login-container .sign-container .sign-in-container .forgot-password-link-container {
          margin-top: 10px;
          display: flex;
          justify-content: center; }
          .login-container .login-container .sign-container .sign-in-container .forgot-password-link-container .forgot-password {
            cursor: pointer;
            font-weight: 500; }
            .login-container .login-container .sign-container .sign-in-container .forgot-password-link-container .forgot-password:hover {
              text-decoration: underline; }
      .login-container .login-container .sign-container .login-sign-up-container {
        flex-basis: 100%;
        color: #fafafa;
        padding: 10px 20px;
        padding-top: 25px;
        padding-bottom: 30px; }
        .login-container .login-container .sign-container .login-sign-up-container h1 {
          font-size: 1.5rem;
          margin-bottom: 25px;
          text-align: center; }
        .login-container .login-container .sign-container .login-sign-up-container p {
          text-align: center;
          line-height: 1.6; }
        .login-container .login-container .sign-container .login-sign-up-container button {
          background-color: #475569;
          color: #fff;
          margin: auto;
          border: 1px solid #fafafa;
          padding: 6px 65px;
          margin-top: 20px;
          margin-bottom: 10px;
          font-family: "Roboto", sans-serif; }
          .login-container .login-container .sign-container .login-sign-up-container button:hover {
            background-color: #fff;
            color: #333;
            border: 1px solid #fff; }
    @media (min-width: 576px) {
      .login-container .login-container {
        width: 340px; }
        .login-container .login-container .logo-container {
          margin-top: calc((100vh - 500px) / 2); }
        .login-container .login-container .sign-container .sign-in-container {
          flex-basis: 100%; } }
    @media (min-width: 768px) {
      .login-container .login-container {
        width: 340px; } }

.records-container {
  position: relative; }
  .records-container .table-container {
    margin-top: 15px; }
    .records-container .table-container table {
      width: 100%;
      margin-bottom: 10px; }
      .records-container .table-container table tr {
        border-bottom: 1px solid #adb5bd; }
        .records-container .table-container table tr:nth-child(odd) {
          background-color: #e5e7eb; }
        .records-container .table-container table tr.header-row {
          background-color: transparent; }
      .records-container .table-container table th,
      .records-container .table-container table td {
        padding: 3px 5px;
        font-size: 0.9rem;
        vertical-align: top; }
      .records-container .table-container table th {
        font-weight: 600; }
        .records-container .table-container table th .sort-item {
          cursor: pointer; }
      .records-container .table-container table .name {
        display: none; }
      .records-container .table-container table .description {
        width: 100%;
        display: block; }
        .records-container .table-container table .description .description-item {
          display: flex; }
          .records-container .table-container table .description .description-item .label {
            font-weight: 500;
            flex-basis: 150px; }
          .records-container .table-container table .description .description-item .value {
            flex-basis: calc(100% - 160px); }
        .records-container .table-container table .description .description-actions {
          display: flex;
          margin-top: 10px;
          grid-gap: 20px;
          gap: 20px; }
      .records-container .table-container table .actions-column {
        width: 50px;
        display: none; }
        .records-container .table-container table .actions-column .actions-container {
          display: flex;
          justify-content: space-between;
          align-items: center; }
      .records-container .table-container table .action-icon:last-child {
        margin-bottom: 0; }
      .records-container .table-container table .action-icon i {
        font-size: 1.2rem;
        cursor: pointer;
        color: #333;
        -webkit-transition: all 200ms;
        transition: all 200ms; }
        .records-container .table-container table .action-icon i.fa-trash {
          color: #c92a2a; }
        .records-container .table-container table .action-icon i:hover {
          -webkit-transform: scale(1.2);
                  transform: scale(1.2); }
      .records-container .table-container table .containers-column {
        display: none; }
      .records-container .table-container table .pieces-column {
        display: none; }
      .records-container .table-container table .transaction-number-column {
        display: none; }
      .records-container .table-container table .desktop-column {
        display: none; }
      .records-container .table-container table .beginnig-balance-column {
        display: none; }
      .records-container .table-container table .ending-balance-column {
        display: none; }
      .records-container .table-container table .mobile-column {
        display: table-cell; }
      .records-container .table-container table .column-filter {
        max-width: 100%; }
        .records-container .table-container table .column-filter input {
          width: 100%;
          padding-left: 3px;
          padding-right: 3px; }
        .records-container .table-container table .column-filter select {
          width: 100%;
          height: 26px;
          padding-left: 3px;
          padding-right: 3px; }
      @media (min-width: 768px) {
        .records-container .table-container table .name {
          display: block; }
        .records-container .table-container table .description {
          display: none; }
        .records-container .table-container table th,
        .records-container .table-container table td {
          padding: 3px 5px;
          font-size: 0.9rem;
          vertical-align: middle; }
        .records-container .table-container table .actions-column {
          width: 60px;
          display: table-cell; }
        .records-container .table-container table .desktop-column {
          display: table-cell; }
        .records-container .table-container table .mobile-column {
          display: none; } }
      @media (min-width: 992px) {
        .records-container .table-container table th,
        .records-container .table-container table td {
          padding: 3px 5px;
          font-size: 0.9rem;
          vertical-align: middle; }
        .records-container .table-container table .actions-column {
          width: 60px; }
        .records-container .table-container table .beginnig-balance-column {
          display: table-cell; }
        .records-container .table-container table .ending-balance-column {
          display: table-cell; } }
      @media (min-width: 1200px) {
        .records-container .table-container table th,
        .records-container .table-container table td {
          padding: 3px 5px;
          font-size: 0.9rem;
          vertical-align: middle; }
        .records-container .table-container table .actions-column {
          width: 60px; }
        .records-container .table-container table .containers-column {
          display: table-cell; }
        .records-container .table-container table .pieces-column {
          display: table-cell; }
        .records-container .table-container table .transaction-number-column {
          display: table-cell; } }
  .records-container .form-container {
    font-size: 0.9rem; }
    .records-container .form-container .loading-container {
      font-size: 1rem;
      margin-top: 25px; }
    .records-container .form-container h2 {
      font-size: 1.3rem;
      font-weight: 500;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      border-top: 1px solid #475569;
      padding-top: 10px; }
    .records-container .form-container .fields {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 5px; }
      .records-container .form-container .fields .field {
        margin-bottom: 12px;
        flex-basis: 100%; }
        .records-container .form-container .fields .field .label {
          font-weight: 500; }
        .records-container .form-container .fields .field select,
        .records-container .form-container .fields .field input[type="text"],
        .records-container .form-container .fields .field input[type="number"],
        .records-container .form-container .fields .field input[type="date"],
        .records-container .form-container .fields .field input[type="password"] {
          width: 100%;
          height: 34px;
          padding-left: 5px;
          padding-right: 5px; }
      .records-container .form-container .fields .date-field {
        margin-bottom: 12px;
        flex-basis: 33%; }
        .records-container .form-container .fields .date-field .label {
          font-weight: 500; }
    .records-container .form-container .buttons-container {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      top: 5px;
      right: 0; }
      .records-container .form-container .buttons-container .save-button {
        width: 120px;
        padding-left: 0;
        padding-right: 0; }
      .records-container .form-container .buttons-container .cancel-button {
        width: 120px;
        padding-left: 0;
        padding-right: 0;
        margin-right: 15px;
        background-color: #f5f5f5;
        color: #475569;
        border: 1px solid #475569; }
        .records-container .form-container .buttons-container .cancel-button:hover {
          background-color: #fa5252;
          border: 1px solid #fa5252;
          color: #fff; }
    @media (min-width: 576px) {
      .records-container .form-container .fields .field {
        flex-basis: 48%; }
      .records-container .form-container .buttons-container {
        position: absolute; } }
    @media (min-width: 768px) {
      .records-container .form-container .fields .field {
        flex-basis: 32%; }
      .records-container .form-container .buttons-container .save-button {
        width: 110px; }
      .records-container .form-container .buttons-container .cancel-button {
        width: 110px;
        margin-right: 20px; } }
    @media (min-width: 992px) {
      .records-container .form-container .buttons-container .save-button {
        width: 140px; }
      .records-container .form-container .buttons-container .cancel-button {
        width: 140px;
        margin-right: 25px; } }
  .records-container h1 {
    font-size: 1.6rem;
    padding-top: 5px;
    margin-bottom: 15px;
    color: #475569; }
  .records-container .header-actions-container {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .records-container .header-actions-container .search-field {
      display: none;
      align-items: center;
      flex-basis: calc(100% - 100px); }
      .records-container .header-actions-container .search-field select {
        height: 32px;
        padding-left: 5px;
        padding-right: 5px; }
      .records-container .header-actions-container .search-field input {
        height: 32px;
        width: calc(100% - 100px);
        max-width: calc(100% - 100px);
        padding-left: 5px;
        padding-right: 5px; }
      .records-container .header-actions-container .search-field button {
        width: 100px;
        margin-left: 5px;
        height: 32px;
        padding: 0; }
      .records-container .header-actions-container .search-field .month-field {
        margin-right: 15px; }
      .records-container .header-actions-container .search-field .year-select {
        width: 70px; }
      .records-container .header-actions-container .search-field .month-select {
        width: 105px; }
    .records-container .header-actions-container .search-field-mobile {
      display: flex;
      flex-wrap: wrap;
      width: 260px; }
      .records-container .header-actions-container .search-field-mobile select {
        height: 32px;
        padding-left: 4px;
        padding-right: 4px; }
      .records-container .header-actions-container .search-field-mobile input {
        height: 32px;
        width: 179px;
        max-width: 179px;
        padding-left: 4px;
        padding-right: 4px;
        margin-top: 5px; }
      .records-container .header-actions-container .search-field-mobile button {
        width: 70px;
        margin-left: 4px;
        margin-right: 4px;
        height: 32px;
        padding: 0; }
      .records-container .header-actions-container .search-field-mobile .month-field {
        margin-right: 0; }
      .records-container .header-actions-container .search-field-mobile .year-select {
        width: 70px; }
      .records-container .header-actions-container .search-field-mobile .month-select {
        width: 105px; }
    .records-container .header-actions-container .new-button-container {
      text-align: right;
      width: 100px;
      margin-top: 4px; }
      .records-container .header-actions-container .new-button-container button {
        width: 100px;
        height: 32px;
        padding: 0;
        margin-bottom: 5px; }
      .records-container .header-actions-container .new-button-container .upload-file-button {
        margin-right: 15px; }
    @media (min-width: 576px) {
      .records-container .header-actions-container h1 {
        font-size: 1.5rem; }
      .records-container .header-actions-container .search-field {
        flex-basis: auto; }
        .records-container .header-actions-container .search-field input {
          width: 220px; } }
    @media (min-width: 768px) {
      .records-container .header-actions-container .search-field {
        width: 550px; }
        .records-container .header-actions-container .search-field input[type="text"] {
          width: 200px; }
        .records-container .header-actions-container .search-field input[type="date"] {
          width: 120px;
          margin-left: 3px; }
        .records-container .header-actions-container .search-field button {
          width: 80px; }
        .records-container .header-actions-container .search-field .filter-field {
          margin-left: 15px; }
        .records-container .header-actions-container .search-field .month-field {
          margin-right: 15px; }
      .records-container .header-actions-container .new-button-container {
        width: 330px; }
        .records-container .header-actions-container .new-button-container button {
          width: 100px; }
        .records-container .header-actions-container .new-button-container .upload-file-button {
          margin-right: 10px; } }
    @media (min-width: 992px) {
      .records-container .header-actions-container .search-field {
        width: 650px;
        display: flex; }
        .records-container .header-actions-container .search-field input[type="text"] {
          width: 200px; }
        .records-container .header-actions-container .search-field input[type="date"] {
          width: 120px;
          margin-left: 3px; }
        .records-container .header-actions-container .search-field button {
          width: 80px; }
        .records-container .header-actions-container .search-field .filter-field {
          margin-left: 15px; }
        .records-container .header-actions-container .search-field .month-field {
          margin-right: 15px; }
      .records-container .header-actions-container .search-field-mobile {
        display: none; }
      .records-container .header-actions-container .new-button-container {
        width: 330px; }
        .records-container .header-actions-container .new-button-container button {
          width: 100px; }
        .records-container .header-actions-container .new-button-container .upload-file-button {
          margin-right: 10px; } }
    @media (min-width: 1200px) {
      .records-container .header-actions-container .search-field {
        width: 650px; }
        .records-container .header-actions-container .search-field input[type="text"] {
          width: 240px; }
        .records-container .header-actions-container .search-field input[type="date"] {
          width: 120px;
          margin-left: 3px; }
        .records-container .header-actions-container .search-field button {
          width: 80px; }
        .records-container .header-actions-container .search-field .filter-field {
          margin-left: 25px; }
        .records-container .header-actions-container .search-field .month-field {
          margin-right: 15px; }
        .records-container .header-actions-container .search-field .year-select {
          width: 100px; }
        .records-container .header-actions-container .search-field .month-select {
          width: 120px; }
      .records-container .header-actions-container .new-button-container {
        width: 380px; }
        .records-container .header-actions-container .new-button-container button {
          width: 110px; }
        .records-container .header-actions-container .new-button-container .upload-file-button {
          margin-right: 15px; } }

.records-delete-modal-container {
  padding: 10px; }
  .records-delete-modal-container h1 {
    font-size: 1.2rem;
    font-weight: 500; }
  .records-delete-modal-container .notice {
    margin-top: 5px;
    border: 2px solid #d32f2f;
    border-left: 8px solid #d32f2f;
    padding: 8px;
    border-radius: 3px; }
    .records-delete-modal-container .notice span {
      color: #d32f2f; }
  .records-delete-modal-container p {
    margin-bottom: 10px;
    margin-top: 10px; }
  .records-delete-modal-container .field {
    margin-top: 10px;
    margin-bottom: 10px; }
    .records-delete-modal-container .field select {
      height: 32px;
      padding-left: 5px;
      padding-right: 5px; }

.records-delete-dialog {
  max-width: 400px;
  left: calc((100vw - 400px) / 2); }
  .records-delete-dialog .modal-body,
  .records-delete-dialog .modal-footer {
    background-color: #fff; }
  .records-delete-dialog .action-button {
    padding-left: 30px;
    padding-right: 30px;
    background-color: #d32f2f;
    color: #fff;
    border: 1px solid #c62828;
    border-radius: 0; }
  .records-delete-dialog .action-button-cancel {
    background-color: #ddd;
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 15px;
    border-radius: 0;
    border: 1px solid #bdbdbd; }

.upload-file-modal-container {
  padding: 10px; }
  .upload-file-modal-container h1 {
    font-size: 1.2rem;
    font-weight: 500; }
  .upload-file-modal-container .type-field {
    margin-top: 25px; }
    .upload-file-modal-container .type-field select {
      height: 28px;
      margin-left: 5px; }
  .upload-file-modal-container .file-field {
    margin-top: 12px; }

.upload-file-dialog {
  max-width: 400px;
  left: calc((100vw - 400px) / 2); }
  .upload-file-dialog .modal-body, .upload-file-dialog .modal-footer {
    background-color: #fff; }
  .upload-file-dialog .action-button {
    padding-left: 30px;
    padding-right: 30px;
    background-color: #fa5252;
    color: #fff;
    border: 1px solid #fa5252;
    border-radius: 0; }
  .upload-file-dialog .action-button-cancel {
    background-color: #ddd;
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 15px;
    border-radius: 0;
    border: 1px solid #bdbdbd; }

