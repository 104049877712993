@import "../../variables.scss";

.table-container {
  margin-top: 15px;
  table {
    width: 100%;
    margin-bottom: 10px;
    tr {
      border-bottom: 1px solid #adb5bd;
      &:nth-child(odd) {
        background-color: #e5e7eb;
      }
      &.header-row {
        background-color: transparent;
      }
    }
    th,
    td {
      padding: 3px 5px;
      font-size: 0.9rem;
      vertical-align: top;
    }
    th {
      font-weight: 600;
      .sort-item {
        cursor: pointer;
      }
    }
    .name {
      display: none;
    }
    .description {
      width: 100%;
      display: block;
      .description-item {
        display: flex;
        .label {
          font-weight: 500;
          flex-basis: 150px;
        }
        .value {
          flex-basis: calc(100% - 160px);
        }
      }
      .description-actions {
        display: flex;
        margin-top: 10px;
        gap: 20px;
      }
    }
    .actions-column {
      width: 50px;
      display: none;
      .actions-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .action-icon {
      &:last-child {
        margin-bottom: 0;
      }
      i {
        font-size: 1.2rem;
        cursor: pointer;
        color: #333;
        transition: all 200ms;
        &.fa-trash {
          color: #c92a2a;
        }
        &:hover {
          transform: scale(1.2);
        }
      }
    }
    .containers-column {
      display: none;
    }
    .pieces-column {
      display: none;
    }
    .transaction-number-column {
      display: none;
    }
    .desktop-column {
      display: none;
    }
    .beginnig-balance-column {
      display: none;
    }
    .ending-balance-column {
      display: none;
    }
    .mobile-column {
      display: table-cell;
    }
    .column-filter {
      max-width: 100%;
      input {
        width: 100%;
        padding-left: 3px;
        padding-right: 3px;
      }
      select {
        width: 100%;
        height: 26px;
        padding-left: 3px;
        padding-right: 3px;
      }
    }

    @media (min-width: 768px) {
      .name {
        display: block;
      }
      .description {
        display: none;
      }
      th,
      td {
        padding: 3px 5px;
        font-size: 0.9rem;
        vertical-align: middle;
      }
      .actions-column {
        width: 60px;
        display: table-cell;
      }
      .desktop-column {
        display: table-cell;
      }
      .mobile-column {
        display: none;
      }
    }
    @media (min-width: 992px) {
      th,
      td {
        padding: 3px 5px;
        font-size: 0.9rem;
        vertical-align: middle;
      }
      .actions-column {
        width: 60px;
      }
      .beginnig-balance-column {
        display: table-cell;
      }
      .ending-balance-column {
        display: table-cell;
      }
    }
    @media (min-width: 1200px) {
      th,
      td {
        padding: 3px 5px;
        font-size: 0.9rem;
        vertical-align: middle;
      }
      .actions-column {
        width: 60px;
      }
      .containers-column {
        display: table-cell;
      }
      .pieces-column {
        display: table-cell;
      }
      .transaction-number-column {
        display: table-cell;
      }
    }
  }
}
