@import "../../variables.scss";

.dashboard-container {
  @import "Charts/Charts.scss";
  position: relative;
  padding-bottom: 30px;
  h1 {
    font-size: 1.6rem;
    padding-top: 5px;
    margin-bottom: 15px;
    color: $primary-color;
  }
  .header-actions-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .search-field {
      display: flex;
      align-items: center;
      flex-basis: calc(100% - 100px);
      select {
        height: 32px;
        width: 120px;
        padding-left: 5px;
        padding-right: 5px;
        margin-right: 8px;
      }
      button {
        width: 100px;
        margin-right: 5px;
        height: 32px;
        padding: 0;
      }
    }
  }
  .loading-data {
    margin-top: 35px;
    text-align: center;
    width: 100%;
    font-size: 0.95rem;
  }
}
