.term-of-use-modal-container {
  padding: 10px;
  color: #000;
  h1 {
    padding-top: 10px;
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 15px;
    color: #000;
    margin-bottom: 30px;
  }
  p {
    text-align: justify;
  }
  .date {
    text-align: right;
  }
}

.term-of-use-dialog {
  .modal-dialog {
    max-width: 900px;
    left: 0;
    background-color: #f5f5f5;
  }
  .modal-body,
  .modal-footer {
    background-color: #f5f5f5;
  }
  .action-button {
    background-color: #4e738a;
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 15px;
    border-radius: 0;
    color: #fff;
    border: 1px solid #4e738a;
    width: 120px;
    &:hover {
      background-color: #76777a;
      color: #fff;
    }
  }
  .action-button-cancel {
    background-color: #76777a;
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 15px;
    border-radius: 0;
    border: 1px solid #76777a;
    color: #fff;
    &:hover {
      background-color: #b1b1b1;
      color: #fff;
    }
  }
}
