@import "../../variables.scss";

.admin-users-container {
  @import "Table/Table.scss";
  @import "Form/Form.scss";
  position: relative;
  h1 {
    font-size: 1.6rem;
    padding-top: 5px;
    margin-bottom: 15px;
    color: $primary-color;
  }
  .header-actions-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .search-field {
      display: flex;
      align-items: center;
      flex-basis: calc(100% - 100px);
      input {
        height: 32px;
        width: calc(100% - 100px);
        max-width: calc(100% - 100px);
        padding-left: 5px;
        padding-right: 5px;
      }
      button {
        width: 100px;
        margin-left: 5px;
        margin-right: 5px;
        height: 32px;
        padding: 0;
      }
    }
    .new-button-container {
      text-align: right;
      button {
        width: 100px;
        height: 32px;
        padding: 0;
      }
    }

    @media (min-width: 576px) {
      h1 {
        font-size: 1.5rem;
      }
      .search-field {
        flex-basis: auto;
        input {
          width: 220px;
        }
      }
    }
    @media (min-width: 768px) {
      .search-field {
        input {
          width: 220px;
        }
        button {
          width: 120px;
        }
      }
      .new-button-container {
        button {
          width: 120px;
        }
      }
    }
    @media (min-width: 992px) {
      .search-field {
        input {
          width: 250px;
        }
        button {
          width: 120px;
        }
      }
      .new-button-container {
        button {
          width: 140px;
        }
      }
    }
  }
}

@import "DeleteModal/DeleteModal.scss";
