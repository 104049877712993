@import "../../variables.scss";

.records-delete-modal-container {
  padding: 10px;
  h1 {
    font-size: 1.2rem;
    font-weight: 500;
  }
  .notice {
    margin-top: 5px;
    border: 2px solid #d32f2f;
    border-left: 8px solid #d32f2f;
    padding: 8px;
    border-radius: 3px;
    span {
      color: #d32f2f;
    }
  }
  p {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .field {
    margin-top: 10px;
    margin-bottom: 10px;
    select {
      height: 32px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.records-delete-dialog {
  max-width: 400px;
  left: calc((100vw - 400px) / 2);
  .modal-body,
  .modal-footer {
    background-color: #fff;
  }
  .action-button {
    padding-left: 30px;
    padding-right: 30px;
    background-color: #d32f2f;
    color: #fff;
    border: 1px solid #c62828;
    border-radius: 0;
  }
  .action-button-cancel {
    background-color: #ddd;
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 15px;
    border-radius: 0;
    border: 1px solid #bdbdbd;
  }
}
