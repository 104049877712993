@import "../../variables.scss";

.records-container {
  @import "Table/Table.scss";
  @import "Form/Form.scss";
  position: relative;
  h1 {
    font-size: 1.6rem;
    padding-top: 5px;
    margin-bottom: 15px;
    color: $primary-color;
  }
  .header-actions-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .search-field {
      display: none;
      align-items: center;
      flex-basis: calc(100% - 100px);
      select {
        height: 32px;
        padding-left: 5px;
        padding-right: 5px;
      }
      input {
        height: 32px;
        width: calc(100% - 100px);
        max-width: calc(100% - 100px);
        padding-left: 5px;
        padding-right: 5px;
      }
      button {
        width: 100px;
        margin-left: 5px;
        height: 32px;
        padding: 0;
      }
      .month-field {
        margin-right: 15px;
      }
      .year-select {
        width: 70px;
      }
      .month-select {
        width: 105px;
      }
    }
    .search-field-mobile {
      display: flex;
      flex-wrap: wrap;
      width: 260px;
      select {
        height: 32px;
        padding-left: 4px;
        padding-right: 4px;
      }
      input {
        height: 32px;
        width: 179px;
        max-width: 179px;
        padding-left: 4px;
        padding-right: 4px;
        margin-top: 5px;
      }
      button {
        width: 70px;
        margin-left: 4px;
        margin-right: 4px;
        height: 32px;
        padding: 0;
      }
      .month-field {
        margin-right: 0;
      }
      .year-select {
        width: 70px;
      }
      .month-select {
        width: 105px;
      }
    }
    .new-button-container {
      text-align: right;
      width: 100px;
      margin-top: 4px;
      button {
        width: 100px;
        height: 32px;
        padding: 0;
        margin-bottom: 5px;
      }
      .upload-file-button {
        margin-right: 15px;
      }
    }
    
    @media (min-width: 576px) {
      h1 {
        font-size: 1.5rem;
      }
      .search-field {
        flex-basis: auto;
        input {
          width: 220px;
        }
      }
    }
    @media (min-width: 768px) {
      .search-field {
        width: 550px;
        input[type="text"] {
          width: 200px;
        }
        input[type="date"] {
          width: 120px;
          margin-left: 3px;
        }
        button {
          width: 80px;
        }
        .filter-field {
          margin-left: 15px;
        }
        .month-field {
          margin-right: 15px;
        }
      }
      .new-button-container {
        width: 330px;
        button {
          width: 100px;
        }
        .upload-file-button {
          margin-right: 10px;
        }
      }
    }
    @media (min-width: 992px) {
      .search-field {
        width: 650px;
        display: flex;
        input[type="text"] {
          width: 200px;
        }
        input[type="date"] {
          width: 120px;
          margin-left: 3px;
        }
        button {
          width: 80px;
        }
        .filter-field {
          margin-left: 15px;
        }
        .month-field {
          margin-right: 15px;
        }
      }
      .search-field-mobile {
        display: none;
      }
      .new-button-container {
        width: 330px;
        button {
          width: 100px;
        }
        .upload-file-button {
          margin-right: 10px;
        }
      }
    }
    @media (min-width: 1200px) {
      .search-field {
        width: 650px;
        input[type="text"] {
          width: 240px;
        }
        input[type="date"] {
          width: 120px;
          margin-left: 3px;
        }
        button {
          width: 80px;
        }
        .filter-field {
          margin-left: 25px;
        }
        .month-field {
          margin-right: 15px;
        }
        .year-select {
          width: 100px;
        }
        .month-select {
          width: 120px;
        }
      }
      .new-button-container {
        width: 380px;
        button {
          width: 110px;
        }
        .upload-file-button {
          margin-right: 15px;
        }
      }
    }
  }
}

@import "DeleteModal/DeleteModal.scss";
@import "UploadFileModal/UploadFileModal.scss";
