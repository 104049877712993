@import "../../variables.scss";

.form-container {
  font-size: 0.9rem;
  .loading-container {
    font-size: 1rem;
    margin-top: 25px;
  }
  h2 {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    border-top: 1px solid $primary-color;
    padding-top: 10px;
  }
  .fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 5px;
    .field {
      margin-bottom: 12px;
      flex-basis: 100%;
      .label {
        font-weight: 500;
      }
      select,
      input[type="text"],
      input[type="number"],
      input[type="date"],
      input[type="password"] {
        width: 100%;
        height: 34px;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
    .date-field {
      margin-bottom: 12px;
      flex-basis: 33%;
      .label {
        font-weight: 500;
      }
    }
  }
  .buttons-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    top: 5px;
    right: 0;
    .save-button {
      width: 120px;
      padding-left: 0;
      padding-right: 0;
    }
    .cancel-button {
      width: 120px;
      padding-left: 0;
      padding-right: 0;
      margin-right: 15px;
      background-color: #f5f5f5;
      color: $primary-color;
      border: 1px solid $primary-color;
      &:hover {
        background-color: $secondary-color;
        border: 1px solid $secondary-color;
        color: #fff;
      }
    }
  }

  @media (min-width: 576px) {
    .fields {
      .field {
        flex-basis: 48%;
      }
    }
    .buttons-container {
      position: absolute;
    }
  }
  @media (min-width: 768px) {
    .fields {
      .field {
        flex-basis: 32%;
      }
    }
    .buttons-container {
      .save-button {
        width: 110px;
      }
      .cancel-button {
        width: 110px;
        margin-right: 20px;
      }
    }
  }
  @media (min-width: 992px) {
    .buttons-container {
      .save-button {
        width: 140px;
      }
      .cancel-button {
        width: 140px;
        margin-right: 25px;
      }
    }
  }
}
