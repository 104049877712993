@import "../../variables.scss";

.header-container {
  position: fixed;
  width: 100%;
  z-index: 100;
  .menu {
    display: none;
    background-color: darken($primary-color, 5);
    padding-right: 20px;
    color: #fff;
    .content {
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .logo {
        width: 140px;
        height: 100%;
        background-color: #e2e8f0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 80%;
        }
      }
      .menu-items {
        display: flex;
        align-items: center;
        color: #fff;
        font-weight: 500;
        .data-type {
          margin-right: 40px;
          select {
            height: 30px;
            margin-left: 5px;
            width: 130px;
          }
        }
        .welcome {
          margin-right: 40px;
          font-weight: 400;
        }
        .menu-item {
          cursor: pointer;
          &:hover {
            color: #fff;
          }
        }
        a {
          color: #fff;
          &:hover {
            color: #fff;
            text-decoration: none;
          }
        }
        .divider {
          width: 30px;
          text-align: center;
          font-size: 0.8rem;
        }
      }
      .logout-link {
        cursor: pointer;
        margin-right: 10px;
        font-size: 0.95rem;
        &:hover {
          color: $secondary-color;
        }
      }
    }

    @media (min-width: 768px) {
      display: block;
    }
  }
  .mobile-menu {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    padding-left: 15px;
    padding-right: 15px;
    .logo {
      width: 140px;
      img {
        width: 100%;
      }
    }
    .menu-icon,
    .cart-icon {
      font-size: 1.4rem;
      a {
        color: #333;
      }
    }

    @media (min-width: 576px) {
      .logo {
        width: 140px;
      }
    }
    @media (min-width: 768px) {
      display: none;
    }
  }
  .sidebar-menu {
    position: fixed;
    background-color: #fff;
    width: 0;
    height: 100vh;
    z-index: 100;
    transition: width 0.4s;
    overflow: hidden;
    white-space: nowrap;
    &.show-menu {
      width: 250px;
      .sidebar-content {
        visibility: visible;
      }
    }
    .sidebar-content {
      visibility: hidden;
      overflow: hidden;
      .close-icon {
        text-align: right;
        margin-right: 18px;
        margin-top: 8px;
        font-size: 1.6rem;
      }
      .menu-items {
        margin-top: 20px;
        ul {
          list-style: none;
          padding-left: 25px;
          li {
            margin-bottom: 16px;
            color: #862633;
            a {
              color: #862633;
            }
          }
        }
      }
    }
  }
  .overlay {
    position: fixed;
    width: 0;
    height: 0;
    background-color: #333;
    opacity: 0;
    z-index: 99;
    transition: opacity 0.4s;
    &.show-menu {
      opacity: 0.7;
      width: 100vh;
      width: -webkit-fill-available;
      height: 100vh;
    }
  }
}
