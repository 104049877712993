@import "../../variables.scss";

.sidebar-container {
  flex-basis: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: darken($primary-color, 5);
  color: #f1f3f5;
  display: none;
  position: fixed;
  min-height: calc(100vh - 50px);
  .sidebar-item {
    font-size: 0.85rem;
    margin-bottom: 15px;
    cursor: pointer;
    width: 140px;
    a {
      color: #f1f3f5;
      display: block;
      padding: 8px 18px;
      &:hover {
        text-decoration: none;
        background-color: $secondary-color;
        color: #f1f3f5 !important;
      }
    }
    i {
      margin-right: 5px;
    }
    &.active {
      background-color: $secondary-color;
    }
  }
  .help-desk {
    a {
      display: flex;
      .open-tickets {
        background-color: #d6336c;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        font-size: 0.7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
      }
    }
  }
  .term-of-use {
    position: absolute;
    bottom: 15px;
    text-align: center;
    width: 140px;
    a {
      color: #f1f3f5;
    }
  }

  @media (min-width: 768px) {
    display: block;
    flex-basis: 140px;
  }
}
