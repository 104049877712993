@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

@import "components/Pagination/Pagination.scss";
@import "variables.scss";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #f5f5f5;
  color: #333;
  font-size: 0.9rem;
  // font-family: 'Roboto', sans-serif;
  font-family: "Inter", sans-serif;
  overflow-x: hidden;

  &.no-scroll {
    overflow: hidden;
  }
}

.required {
  color: #dd4b39;
  font-size: 14px;
}

a {
  color: #2196f3;
}

.small-dialog {
  max-width: 500px;
  left: calc((100vw - 500px) / 2);
}

.hidden {
  display: none !important;
}

.success-notification,
.info-notification,
.danger-notification {
  width: 320px;
  left: calc((100% - 320px) / 2);
  opacity: 0.95;
  position: fixed;
  top: 35px;
  z-index: 1600;
  color: #ffffff;
  border-radius: 3px;
  padding: 10px 10px;
  font-size: 15px;
  box-shadow: 0 0 20px #999;
  .icon {
    float: left;
    i {
      font-size: 24px;
    }
  }
  .text {
    float: left;
    margin-left: 10px;
    margin-top: 2px;
    width: 245px;
  }
}

.success-notification {
  background-color: #4caf50;
}

.info-notification {
  background-color: #2196f3;
}

.danger-notification {
  background-color: #f44336;
}

button {
  font-size: 0.85rem;
  background-color: $primary-color;
  color: #fff;
  border: 1px solid $primary-color;
  padding: 6px 35px;
  font-weight: 400;
  transition: all 200ms ease-in-out;
  &:hover {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
    color: #fff;
  }
}

.admin-button {
  font-size: 0.85rem;
  background-color: $secondary-color;
  color: #fff;
  border: 1px solid $secondary-color;
  padding: 6px 35px;
  font-weight: 400;
  transition: all 200ms ease-in-out;
  &:hover {
    background-color: lighten($secondary-color, 10);
    border: 1px solid lighten($secondary-color, 10);
    color: #fff;
  }
}
