@import "../../variables.scss";

.charts-container {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 12px;
  justify-content: space-between;
  h1 {
    flex-basis: 100%;
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 1.5rem;
    color: #fa5252;
  }
  .chart-container {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    padding: 15px;
    flex-basis: 100%;
    h2 {
      color: #475569;
      font-size: 1.3rem;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      .chart-title {
        width: calc(100% - 300px);
      }
      .chart-type-field {
        display: flex;
        align-items: center;
        font-size: 0.95rem;
        span {
          font-weight: 500;
        }
        select {
          margin-left: 5px;
          width: 70px;
          height: 28px;
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }
    .no-data {
      text-align: center;
      padding-top: 30px;
      font-size: 1rem;
    }
    .total {
      margin-top: 15px;
      h2 {
        font-size: 1.1rem;
        display: block;
      }
      .value {
        margin-left: 5px;
        color: #0d9488;
        font-size: 1.4rem;
      }
    }
  }

  @media (min-width: 576px) {
    .chart-container {
      flex-basis: 100%;
    }
  }
  @media (min-width: 768px) {
    .chart-container {
      flex-basis: 100%;
    }
  }
  @media (min-width: 992px) {
    .chart-container {
      flex-basis: 100%;
    }
  }
  @media (min-width: 1200px) {
    .chart-container {
      flex-basis: calc(50% - 12px);
    }
  }
}
